import {
  ExpressionType,
  OperationType
} from './enum'

/**
 * The environment represents the environment in which it is interpreted
 * It contains the variables and stepper
 */
export class Stepper {
  constructor () {
    this.speed = 100
    this.maxSpeed = 100
    this.acceleration = 100
    this.current = 0
    this.goal = 0
  }

  moveAbs (pos) {
    // if (this.el.attr('id') === 's240') {
    //   console.log('s240', pos)
    //   console.trace()
    // }
    if (this.goal !== pos) {
      this.goal = pos
      return true
    }
    return false
  }

  moveRel (pos) {
    return this.moveAbs(this.current + pos)
  }

  calibrate (pos) {
    this.goal = 0
    this.moveAbs(0)
    console.log('calibrate', pos)
    return 1
  }

  setMaxSpeed (v) {
    if (this.maxSpeed !== v) {
      this.maxSpeed = v
      return true
    }
    return false
  }

  setSpeed (v) {
    if (this.speed !== v) {
      this.speed = v
      return true
    }
    return false
  }

  setAcceleration (v) {
    if (this.acceleration !== v) {
      this.acceleration = v
      return true
    }
    return false
  }

  digitalWrite (pin, type) {
    console.log('digitalWrite ', pin, ' ', type)
  }

  enable () { console.log('enable') }
  disable () { console.log('disable') }
  stop () { console.log('stop') }
  restart () { console.log('restart') }
}

export class Environment {
  constructor (vars, step) {
    this.variables = vars
    this.stepper = step
  }
}

export class Expression {
  constructor (type, left, right, value) {
    this.m_type = type
    this.m_left = left
    this.m_right = right
    this.m_value = value
  }

  getValue (env) {
    const first = ExpressionType[this.m_type].substring(0, 1)
    switch (this.m_type) {
      case ExpressionType.OP_GREATER: return this.m_left.getValue(env) > this.m_right.getValue(env)
      case ExpressionType.OP_SMALLER: return this.m_left.getValue(env) < this.m_right.getValue(env)
      case ExpressionType.OP_EQUAL: return this.m_left.getValue(env) === this.m_right.getValue(env)
      case ExpressionType.OP_NOT_EQUAL: return this.m_left.getValue(env) !== this.m_right.getValue(env)
      case ExpressionType.OP_AND: return this.m_left.getValue(env) && this.m_right.getValue(env)
      case ExpressionType.OP_OR: return this.m_left.getValue(env) || this.m_right.getValue(env)
      case ExpressionType.OP_PLUS: return this.m_left.getValue(env) + this.m_right.getValue(env)
      case ExpressionType.OP_MINUS: return this.m_left.getValue(env) - this.m_right.getValue(env)
      case ExpressionType.OP_MUL: return this.m_left.getValue(env) * this.m_right.getValue(env)
      case ExpressionType.OP_DIV: return Math.round(this.m_left.getValue(env) / this.m_right.getValue(env))
      case ExpressionType.OP_MOD: return this.m_left.getValue(env) % this.m_right.getValue(env)
      case ExpressionType.I_ATOMAR:
        if (this.m_value === 'X1') {
          return env.variables.X1
        } else if (this.m_value === 'X2') {
          return env.variables.X2
        } else {
          return this.m_value
        }
      case ExpressionType.I_FUNCTION: return this.m_left.eval(env)
      case ExpressionType.C_STEPS_TO_GO:
        // console.log('steps', { goal: env.stepper.goal, current: env.stepper.current })
        return env.stepper.goal - env.stepper.current
      case ExpressionType.C_CURRENT_STEP: return env.stepper.current
      case ExpressionType.C_IS_CALIBRATED: return 1
      default:
        if (first === 'C' || first === 'V' || first === 'I' || first === 'D') {
          return env.variables[this.m_type]
        }
        console.warn('unknown type', this)
    }
  }

  printCpp () {
    if (this.m_type === ExpressionType.I_ATOMAR) {
      return 'new AtomarExpression(' + this.m_value + ')'
    } else if (this.m_type === ExpressionType.I_FUNCTION) {
      return 'new FunctionExpression(&allOperations[' + this.m_leftidx + '])'
    } else if (ExpressionType[this.m_type].substring(0, 2) === 'OP') {
      const leftIdx = this.m_leftidx === null ? null : 'allExpressions[' + this.m_leftidx + ']'
      const rightIdx = this.m_rightidx === null ? null : 'allExpressions[' + this.m_rightidx + ']'
      return 'new BinaryExpression(' + ExpressionType[this.m_type] + ', ' + leftIdx + ', ' + rightIdx + ')'
    } else {
      return 'new VariableExpression(' + ExpressionType[this.m_type] + ')'
    }
  }

  equals (other) {
    if (other === null || other === undefined) {
      return false
    }
    return this.m_type === other.m_type &&
      this.m_left === other.m_left &&
      this.m_right === other.m_right &&
      this.m_value === other.m_value
  }
}

// --- Operations
export class OpRoot {
  constructor (subOperations) {
    this.m_subOperations = subOperations
    // will be set later after a transformation happened
    this.m_subIdx = null // always points to 0 but leave it here for completeness
    this.m_subLength = null
    this.m_type = OperationType.OT_ROOT
  }

  eval (env) {
    for (let i = 0; i < this.m_subOperations.length; i++) {
      this.m_subOperations[i].eval(env)
    }
  }

  updateLengthWhenChildIsOpElse (operationsWithSplitIfElse) {
    let i = 0
    if (i > this.m_subLength || this.m_subIdx + i >= operationsWithSplitIfElse.length) {
      console.error('could not update length - maybe code is empty?')
      return
    }
    while (true) {
      const otherOp = operationsWithSplitIfElse[this.m_subIdx + i]
      otherOp.updateLengthWhenChildIsOpElse(operationsWithSplitIfElse)
      console.log('root', OperationType[otherOp.m_type])
      if (otherOp instanceof OpIfElse && otherOp.m_elseOperations != null) {
        this.m_subLength++
      }
      i++
      if (i >= this.m_subLength || this.m_subIdx + i >= operationsWithSplitIfElse.length) {
        break
      }
    }
  }

  printCpp () {
    return 'new OpRoot(&allOperations[' + this.m_subIdx + '], ' + this.m_subLength + ')'
  }
}

export class OpIfElse {
  constructor (condition, subOperations, elseOperations = null) {
    this.m_condition = condition
    this.m_subOperations = subOperations
    this.m_elseOperations = elseOperations
    // will be set later after a transformation happened
    this.m_subIdx = null
    this.m_subLength = null
    this.m_type = OperationType.OT_IF
  }

  eval (env) {
    if (this.m_condition.getValue(env)) {
      for (let i = 0; i < this.m_subOperations.length; i++) {
        this.m_subOperations[i].eval(env)
      }
    } else if (this.m_elseOperations != null) {
      for (let i = 0; i < this.m_elseOperations.length; i++) {
        this.m_elseOperations[i].eval(env)
      }
    }
  }

  updateLengthWhenChildIsOpElse (operationsWithSplitIfElse) {
    let i = 0
    // may happen with an empty program
    if (i > this.m_subLength || this.m_subIdx + i >= operationsWithSplitIfElse.length) {
      console.error('could not update length - maybe code is empty?')
      return
    }
    //if (this.m_condition) {
    //  console.log('updateLengthWhenChildIsOpElse ', OperationType[this.m_type], this.m_subIdx, this.m_subLength, this.m_condition.m_right.m_value)
    //}else {
    //  console.log('updateLengthWhenChildIsOpElse ', OperationType[this.m_type], this.m_subIdx, this.m_subLength)
    //}
    while (true) {
      const otherOp = operationsWithSplitIfElse[this.m_subIdx + i]
      otherOp.updateLengthWhenChildIsOpElse(operationsWithSplitIfElse)
      if (otherOp instanceof OpIfElse && otherOp.m_elseOperations != null) {
        //if (this.m_condition) {
        //  console.log('inc length', OperationType[this.m_type], this.m_conditionIdx, this.m_subLength, this.m_condition.m_right.m_value, operationsWithSplitIfElse)
        //} else {
        //  console.log('inc length', this.m_conditionIdx, this.m_subLength)
        //}
        this.m_subLength++
      }
      i++
      if (i >= this.m_subLength || this.m_subIdx + i >= operationsWithSplitIfElse.length) {
        break
      }
    }
  }

  printCpp () {
    if (this.m_elseOperations != null) {
      return 'new OpIfElse(allExpressions[' + this.m_conditionIdx + '], &allOperations[' + this.m_subIdx + '], ' + this.m_subLength + ', &allOperations[' + this.m_elseIdx + '], ' + this.m_elseLength + ')'
    } else {
      return 'new OpIf(allExpressions[' + this.m_conditionIdx + '], &allOperations[' + this.m_subIdx + '], ' + this.m_subLength + ')'
    }
  }
}

export class OpAssign {
  constructor (variable, value) {
    this.m_variable = variable
    this.m_value = value
    this.m_type = OperationType.OT_ASSIGN
  }

  updateLengthWhenChildIsOpElse () {}

  eval (env) {
    env.variables[this.m_variable] = this.m_value.getValue(env)
  }

  printCpp () {
    return 'new OpAssign(' + ExpressionType[this.m_variable] + ', allExpressions[' + this.m_valueIdx + '])'
  }
}

export class OpError {
  constructor (msg) {
    this.m_msg = msg
  }

  updateLengthWhenChildIsOpElse () {}
  printCpp () {
    return '// ERROR: ' + this.m_msg
  }
}

// --- Move-Operations
export class OpSpeed { constructor (value) { this.m_type = OperationType.OT_SPEED; this.m_value = value }
  updateLengthWhenChildIsOpElse () {}
  eval (env) { return env.stepper.setSpeed(this.m_value.getValue(env)) } printCpp () { return 'new OpSpeed(allExpressions[' + this.m_valueIdx + '])' } }
export class OpMaxSpeed { constructor (value) { this.m_type = OperationType.OT_MAX_SPEED; this.m_value = value }
  updateLengthWhenChildIsOpElse () {}
  eval (env) { return env.stepper.setMaxSpeed(this.m_value.getValue(env)) } printCpp () { return 'new OpMaxSpeed(allExpressions[' + this.m_valueIdx + '])' } }
export class OpAcceleration { constructor (value) { this.m_type = OperationType.OT_ACCELERATION; this.m_value = value }
  updateLengthWhenChildIsOpElse () {}
  eval (env) { return env.stepper.setAcceleration(this.m_value.getValue(env)) } printCpp () { return 'new OpAcceleration(allExpressions[' + this.m_valueIdx + '])' } }
export class OpMoveRel { constructor (value) { this.m_type = OperationType.OT_MOVE_REL; this.m_value = value }
  updateLengthWhenChildIsOpElse () {}
  eval (env) { return env.stepper.moveRel(this.m_value.getValue(env)) } printCpp () { return 'new OpMoveRel(allExpressions[' + this.m_valueIdx + '])' } }
export class OpMoveAbs { constructor (value) { this.m_type = OperationType.OT_MOVE_ABS; this.m_value = value }
  updateLengthWhenChildIsOpElse () {}
  eval (env) { return env.stepper.moveAbs(this.m_value.getValue(env)) } printCpp () { return 'new OpMoveAbs(allExpressions[' + this.m_valueIdx + '])' } }
export class OpCalibrate { constructor (value) { this.m_type = OperationType.OT_CALIBRATE; this.m_value = value }
  updateLengthWhenChildIsOpElse () {}
  eval (env) { return env.stepper.calibrate(this.m_value.getValue(env)) } printCpp () { return 'new OpCalibrate(allExpressions[' + this.m_valueIdx + '])' } }
export class OpEnable { constructor () { this.m_type = OperationType.OT_ENABLE }
  updateLengthWhenChildIsOpElse () {}
  eval (env) { env.stepper.enable() } printCpp () { return 'new OpEnable()' } }
export class OpDisable { constructor () { this.m_type = OperationType.OT_DISABLE }
  updateLengthWhenChildIsOpElse () {}
  eval (env) { env.stepper.disable() } printCpp () { return 'new OpDisable()' } }
export class OpStop { constructor () { this.m_type = OperationType.OT_STOP }
  updateLengthWhenChildIsOpElse () {}
  eval (env) { env.stepper.stop() } printCpp () { return 'new OpStop()' } }
export class OpRestart { constructor () { this.m_type = OperationType.OT_RESTART }
  updateLengthWhenChildIsOpElse () {}
  eval (env) { env.stepper.restart() } printCpp () { return 'new OpRestart()' } }
export class OpDigitalHigh { constructor (value) { this.m_type = OperationType.OT_DIGITAL_HIGH; this.m_value = value }
  updateLengthWhenChildIsOpElse () {}
  eval (env) { env.stepper.digitalWrite(this.m_value.getValue(env), 1) } printCpp () { return 'new OpDigitalHigh(allExpressions[' + this.m_valueIdx + '])' } }
export class OpDigitalLow { constructor (value) { this.m_type = OperationType.OT_DIGITAL_LOW; this.m_value = value }
  updateLengthWhenChildIsOpElse () {}
  eval (env) { env.stepper.digitalWrite(this.m_value.getValue(env), 0) } printCpp () { return 'new OpDigitalLow(allExpressions[' + this.m_valueIdx + '])' } }
export class OpRandom { constructor (value) { this.m_type = OperationType.OT_RANDOM; this.m_value = value }
  updateLengthWhenChildIsOpElse () {}
  eval (env) { return Math.floor(Math.random() * this.m_value.getValue(env)) } printCpp () { return 'new OpRandom(allExpressions[' + this.m_valueIdx + '])' } }
export class OpAbs { constructor (value) { this.m_type = OperationType.OT_ABS; this.m_value = value }
  updateLengthWhenChildIsOpElse () {}
  eval (env) { return Math.abs(this.m_value.getValue(env)) } printCpp () { return 'new OpAbs(allExpressions[' + this.m_valueIdx + '])' } }

export class OpMax {
  constructor (value1, value2) {
    this.m_type = OperationType.OT_MAX
    this.m_value = value1
    this.m_value2 = value2
  }

  updateLengthWhenChildIsOpElse () {}
  eval (env) {
    return Math.max(this.m_value.getValue(env), this.m_value2.getValue(env))
  }

  printCpp () {
    return 'new OpMax(allExpressions[' + this.m_valueIdx + '], allExpressions[' + this.m_value2Idx + '])'
  }
}
export class OpMin {
  constructor (value1, value2) {
    this.m_type = OperationType.OT_Min
    this.m_value = value1
    this.m_value2 = value2
  }

  updateLengthWhenChildIsOpElse () {}
  eval (env) {
    return Math.min(this.m_value.getValue(env), this.m_value2.getValue(env))
  }

  printCpp () {
    return 'new OpMin(allExpressions[' + this.m_valueIdx + '], allExpressions[' + this.m_value2Idx + '])'
  }
}
