
// TODO must be updated in Communication.h too
// TODO write check-script somewhere, that the values are correct in communication.h
// to be more size-efficient, this enum packs operator and variables into one
// TODO - count how many steps were moved since the last calibration: C_STEPS_SINCE_CALIBRATED,
export enum ExpressionType {
    // constants
    C_STEPPER_GROUP,    // 0 numbers in the first row: 0,0,1,1,2,2,3,3...  max is 71
    C_STEPPER_GROUP_NUM,// 1 numbers in the first row: 0,1,0,1,0,1,0,1...  numbers in the second row:2,3,2,3,2,3,2,3...  max is 3
    C_STEPPER_NUM,      // 2 numbers in the first row: 0,1,2,3,4,5,6,7,...  max is 287
    C_STEPPER_ROW,      // 3 numbers in the first row: 0,0,0,0,0,0,0...  max is 11
    C_STEPPER_COL,      // 4 numbers in the first row: 0,1,2,3,4,5,6...  max is 23
    C_STEPS_TO_GO,      // 5 readonly system variables number of steps, the stepper has to go
    C_CURRENT_STEP,     // 6 current step-number relative to calibrated 0-position
    C_IS_CALIBRATED,    // 7 becomes 1 when it is calibrated

    // writable variables
    I_CUSTOM_FIRST,     // 8 should always be the first variable

    D_TIME_MS1,         // 9 time in milliseconds, you can write to it
    D_TIME_MS2,         //10
    D_TIME_MS3,         //11
    D_TIME_MS4,         //12

    // custom variables
    V_CUSTOM1,          //13
    V_CUSTOM2,          //
    V_CUSTOM3,          //
    V_CUSTOM4,          //
    V_CUSTOM5,          //
    V_CUSTOM6,          //
    V_CUSTOM7,          //
    V_CUSTOM8,          //
    V_CUSTOM9,          //
    V_CUSTOM10,         //
    V_CUSTOM11,         //
    V_CUSTOM12,         //
    V_CUSTOM13,         //
    V_CUSTOM14,         //
    V_CUSTOM15,         //
    V_CUSTOM16,         //
    V_CUSTOM17,         //
    V_CUSTOM18,         //
    V_CUSTOM19,         //
    G_CUSTOM1,          // these variables are writable and visible to all steppers on the same machine
    G_CUSTOM2,
    G_CUSTOM3,
    G_CUSTOM4,

    I_CUSTOM_LAST,      // should always be the last variable

    I_FUNCTION,         // internally: will call a function/operation
    I_ATOMAR,           // SPECIAL: will access the int16_t-value directly from the Value-Object

    I_VAR_END,          // only until here the variables can be accessed

    OP_GREATER,         // >
    OP_SMALLER,         // <
    OP_EQUAL,           // ==
    OP_NOT_EQUAL,       // !=
    OP_AND,             // &&
    OP_OR,              // ||
    OP_PLUS,            // +
    OP_MINUS,           // -
    OP_MUL,             // *
    OP_DIV,             // /
    OP_MOD,             // %
    OP_POW,             // ^
    // unary operator
    // none

    I_OP_END // marks when all the operations are finished
};

export enum OperationType {
    OT_ROOT,        //  0
    OT_IF,          //  1
    OT_ELSE,        //  2
    OT_ASSIGN,      //  3
    OT_SPEED,       //  4
    OT_MAX_SPEED,   //  5
    OT_ACCELERATION,//  6
    OT_MOVE_REL,    //  7
    OT_MOVE_ABS,    //  8
    OT_ENABLE,      //  9
    OT_DISABLE,     // 10
    OT_STOP,        // 11
    OT_CALIBRATE,   // 12
    OT_RESTART,     // 13
    OT_DIGITAL_HIGH,// 14
    OT_DIGITAL_LOW, // 15
    OT_RANDOM,      // 16
    OT_ABS,         // 17
    OT_MAX,         // 18
    OT_MIN,         // 19
    OT_IFELSE,      // 20
};


/**
 * the communication codes are used when writing registers
 * - either its just setting some registers see ModbusRegisters
 * - or its used to write a program
 */
export enum WriteRegisterPrefix {
    COM_SEND_OP,        // 0
    COM_SEND_EXP,       // 1
    COM_WRITE_REGISTERS,// 2
    // this is a special prefix to make it especially short/fast
    COM_START,          // 3 init vars + start
    COM_PING,          // 4 nothing
};

export enum RunningStates {
    RUN_IDLE,
    RUN_RUNNING,
};

// TODO we could also use coils for boolean-data: but this would probably make it only more complicated
export enum ModbusRegisters {
  R_LED_STATE, // uint16 to make the led blink
  R_OP_SIZE, // uint16 updating this value will clear + resize the operations
  R_EXP_SIZE, // uint16 updating this value will clear + resize the expressions
  R_RUNNING, // uint16 - see RunningStates (will also be changed by COM_SEND_OP, COM_START, R_OP_SIZE, and more)
  R_VERSION, // uint16 readonly - version of this arduino
  R_NUMBER, // uint16 readonly - number of this arduino
  R_SECONDS, // uint16 - how many seconds have passed since starting the arduino

  R_S1_OFFSET, // int16 - after calibration how many steps to take for getting the 0-position
  R_S2_OFFSET, // int16
  R_S3_OFFSET, // int16
  R_S4_OFFSET, // int16
  R_S1_CALIBRATED, // uint16 - 1 if calibrated, 0 otherwise
  R_S2_CALIBRATED, // uint16
  R_S3_CALIBRATED, // uint16
  R_S4_CALIBRATED, // uint16
  R_S1_ENABLED, // uint16 - 1 if enabled, 0 otherwise
  R_S2_ENABLED, // uint16
  R_S3_ENABLED, // uint16
  R_S4_ENABLED, // uint16
  R_S1_OPT_ACCELERATION_STEPS, // uint16 - optimization - maps to disableAccelerationExceptStepsBefore
  R_S2_OPT_ACCELERATION_STEPS, // uint16
  R_S3_OPT_ACCELERATION_STEPS, // uint16
  R_S4_OPT_ACCELERATION_STEPS, // uint16
  R_S1_OPT_COMPUTE_PERIOD, // uint16 - optimization - how often the acceleration is computed
  R_S2_OPT_COMPUTE_PERIOD, // uint16
  R_S3_OPT_COMPUTE_PERIOD, // uint16
  R_S4_OPT_COMPUTE_PERIOD, // uint16
  R_OPT_INTERPRETER_PERIOD, // uint16 - how often the interpreter is computed - 1=always, 100=every 100 loops
  R_CHECK_VALID, // bool - 1 if the program is valid

  R_MICRO_STEPS, // uint16 - configure the microsteps 2,4,8,16
  R_RANDOM_SEED, // int16 - 0 will use internal random seed, otherwise you can send it a value
  R_VARS, // uint16,int16 - will get the environment vars of each stepper interpreter

  // TODO this is currently copy/paste
  R_S1_C_STEPPER_GROUP,
  R_S1_C_STEPPER_GROUP_NUM,
  R_S1_C_STEPPER_NUM,
  R_S1_C_STEPPER_ROW,
  R_S1_C_STEPPER_COL,
  R_S1_C_STEPS_TO_GO,
  R_S1_C_CURRENT_STEP,
  R_S1_C_IS_CALIBRATED,
  R_S1_I_CUSTOM_FIRST,
  R_S1_D_TIME_MS1,
  R_S1_D_TIME_MS2,
  R_S1_D_TIME_MS3,
  R_S1_V_CUSTOM1,
  R_S1_V_CUSTOM2,
  R_S1_V_CUSTOM3,
  R_S1_V_CUSTOM4,
  R_S1_V_CUSTOM5,
  R_S1_V_CUSTOM6,
  R_S1_V_CUSTOM7,
  R_S1_V_CUSTOM8,
  R_S1_V_CUSTOM9,
  R_S1_V_CUSTOM10,
  R_S1_V_CUSTOM11,
  R_S1_V_CUSTOM12,
  R_S1_V_CUSTOM13,
  R_S1_V_CUSTOM14,
  R_S1_V_CUSTOM15,
  R_S1_V_CUSTOM16,
  R_S1_V_CUSTOM17,
  R_S1_V_CUSTOM18,
  R_S1_V_CUSTOM19,
  R_S1_G_CUSTOM1,
  R_S1_G_CUSTOM2,
  R_S1_G_CUSTOM3,
  R_S1_I_CUSTOM_LAST,

  R_S2_C_STEPPER_GROUP,
  R_S2_C_STEPPER_GROUP_NUM,
  R_S2_C_STEPPER_NUM,
  R_S2_C_STEPPER_ROW,
  R_S2_C_STEPPER_COL,
  R_S2_C_STEPS_TO_GO,
  R_S2_C_CURRENT_STEP,
  R_S2_C_IS_CALIBRATED,
  R_S2_I_CUSTOM_FIRST,
  R_S2_D_TIME_MS1,
  R_S2_D_TIME_MS2,
  R_S2_D_TIME_MS3,
  R_S2_V_CUSTOM1,
  R_S2_V_CUSTOM2,
  R_S2_V_CUSTOM3,
  R_S2_V_CUSTOM4,
  R_S2_V_CUSTOM5,
  R_S2_V_CUSTOM6,
  R_S2_V_CUSTOM7,
  R_S2_V_CUSTOM8,
  R_S2_V_CUSTOM9,
  R_S2_V_CUSTOM10,
  R_S2_V_CUSTOM11,
  R_S2_V_CUSTOM12,
  R_S2_V_CUSTOM13,
  R_S2_V_CUSTOM14,
  R_S2_V_CUSTOM15,
  R_S2_V_CUSTOM16,
  R_S2_V_CUSTOM17,
  R_S2_V_CUSTOM18,
  R_S2_V_CUSTOM19,
  R_S2_G_CUSTOM1,
  R_S2_G_CUSTOM2,
  R_S2_G_CUSTOM3,
  R_S2_I_CUSTOM_LAST,

  R_S3_C_STEPPER_GROUP,
  R_S3_C_STEPPER_GROUP_NUM,
  R_S3_C_STEPPER_NUM,
  R_S3_C_STEPPER_ROW,
  R_S3_C_STEPPER_COL,
  R_S3_C_STEPS_TO_GO,
  R_S3_C_CURRENT_STEP,
  R_S3_C_IS_CALIBRATED,
  R_S3_I_CUSTOM_FIRST,
  R_S3_D_TIME_MS1,
  R_S3_D_TIME_MS2,
  R_S3_D_TIME_MS3,
  R_S3_V_CUSTOM1,
  R_S3_V_CUSTOM2,
  R_S3_V_CUSTOM3,
  R_S3_V_CUSTOM4,
  R_S3_V_CUSTOM5,
  R_S3_V_CUSTOM6,
  R_S3_V_CUSTOM7,
  R_S3_V_CUSTOM8,
  R_S3_V_CUSTOM9,
  R_S3_V_CUSTOM10,
  R_S3_V_CUSTOM11,
  R_S3_V_CUSTOM12,
  R_S3_V_CUSTOM13,
  R_S3_V_CUSTOM14,
  R_S3_V_CUSTOM15,
  R_S3_V_CUSTOM16,
  R_S3_V_CUSTOM17,
  R_S3_V_CUSTOM18,
  R_S3_V_CUSTOM19,
  R_S3_G_CUSTOM1,
  R_S3_G_CUSTOM2,
  R_S3_G_CUSTOM3,
  R_S3_I_CUSTOM_LAST,

  R_S4_C_STEPPER_GROUP,
  R_S4_C_STEPPER_GROUP_NUM,
  R_S4_C_STEPPER_NUM,
  R_S4_C_STEPPER_ROW,
  R_S4_C_STEPPER_COL,
  R_S4_C_STEPS_TO_GO,
  R_S4_C_CURRENT_STEP,
  R_S4_C_IS_CALIBRATED,
  R_S4_I_CUSTOM_FIRST,
  R_S4_D_TIME_MS1,
  R_S4_D_TIME_MS2,
  R_S4_D_TIME_MS3,
  R_S4_V_CUSTOM1,
  R_S4_V_CUSTOM2,
  R_S4_V_CUSTOM3,
  R_S4_V_CUSTOM4,
  R_S4_V_CUSTOM5,
  R_S4_V_CUSTOM6,
  R_S4_V_CUSTOM7,
  R_S4_V_CUSTOM8,
  R_S4_V_CUSTOM9,
  R_S4_V_CUSTOM10,
  R_S4_V_CUSTOM11,
  R_S4_V_CUSTOM12,
  R_S4_V_CUSTOM13,
  R_S4_V_CUSTOM14,
  R_S4_V_CUSTOM15,
  R_S4_V_CUSTOM16,
  R_S4_V_CUSTOM17,
  R_S4_V_CUSTOM18,
  R_S4_V_CUSTOM19,
  R_S4_G_CUSTOM1,
  R_S4_G_CUSTOM2,
  R_S4_G_CUSTOM3,
  R_S4_I_CUSTOM_LAST,

  R_MAX
};

export const Register = ModbusRegisters
