import {
  Stepper,
  Environment
} from '../steplang/model'
import { ExpressionType } from '../steplang/enum'
import jQuery from 'jquery'

// TODO maybe convert the ~200 lines of accelstepper so we have it 100% the same1
class RunStepper extends Stepper {
  constructor (el) {
    super()
    this.el = el
    this.lastStep = null
  }

  step (timeDiffMs) {
    if (this.goal === this.current) {
      return
    }
    const steps = timeDiffMs / 1000 * this.maxSpeed
    if (this.goal > this.current) {
      this.current = this.current + steps
      if (this.goal < this.current) {
        this.current = this.goal
      }
    } else if (this.goal < this.current) {
      this.current = this.current - steps
      if (this.goal > this.current) {
        this.current = this.goal
      }
    }
    // if (this.el.attr('id') === 's240') {
    //   console.log(this.el.id, this.current, (this.current * 360) / (200 * 16 * 4))
    // }
    this.el.css('transform', 'rotate(' + (-1 * this.current * 360) / (200 * 16 * 4) + 'deg)')
  }

  digitalWrite (pin, type) {
    if (type === 1) {
      this.el.css('background-color', 'lightgreen')
    } else {
      this.el.css('background-color', 'white')
    }
  }
}

export class Arduino {
  constructor (number) {
    this.root = null
    this.env = []
    for (let s = 0; s < 4; s++) {
      const vars = {}
      for (const key in ExpressionType) {
        const first = key.substring(0, 1)
        if (first === 'C' || first === 'V' || first === 'I' || first === 'D') {
          vars[ExpressionType[key]] = 0
        }
      }
      vars[ExpressionType.C_STEPPER_GROUP] = number - 1
      vars[ExpressionType.C_STEPPER_GROUP_NUM] = s
      if (s === 0 || s === 1) {
        vars[ExpressionType.C_STEPPER_ROW] = 2 * Math.floor((number - 1) / 12)
      } else {
        vars[ExpressionType.C_STEPPER_ROW] = 2 * Math.floor((number - 1) / 12) + 1
      }
      if (s === 0 || s === 2) {
        vars[ExpressionType.C_STEPPER_COL] = 2 * ((number - 1) % 12)
      } else {
        vars[ExpressionType.C_STEPPER_COL] = 2 * ((number - 1) % 12) + 1
      }
      vars[ExpressionType.C_STEPPER_NUM] = 24 * vars[ExpressionType.C_STEPPER_ROW] + vars[ExpressionType.C_STEPPER_COL]
      //console.log(number * 4 + s - 4, number, vars[ExpressionType.C_STEPPER_NUM], number, vars[ExpressionType.C_STEPPER_COL], vars[ExpressionType.C_STEPPER_ROW])
      //jQuery("#s" + (number * 4 + s - 4)).html(s)
      //jQuery("#s" + (number * 4 + s - 4)).html(vars[ExpressionType.C_STEPPER_ROW])

      const stepper = new RunStepper(jQuery('#s' + (number * 4 + s - 4)))
      vars.X1 = parseInt(jQuery('#prog_x1').val())
      vars.X2 = parseInt(jQuery('#prog_x2').val())
      this.env.push(new Environment(vars, stepper))
    }
  }

  setRoot (oproot) {
    this.root = oproot
  }

  loop (timeDiffMs) {
    for (let s = 0; s < 4; s++) {
      this.env[s].variables[ExpressionType.D_TIME_MS1] += timeDiffMs
      this.env[s].variables[ExpressionType.D_TIME_MS2] += timeDiffMs
      this.env[s].variables[ExpressionType.D_TIME_MS3] += timeDiffMs
      this.env[s].variables[ExpressionType.D_TIME_MS4] += timeDiffMs
      this.root.eval(this.env[s])
      this.env[s].stepper.step(timeDiffMs)
    }
  }
}

export class Run {
  constructor () {
    this.areaEl = null
    this.arduinos = null
    this.runTimeout = null
  }

  init (oproot) {
    this.areaEl = jQuery('#area')
    this.areaEl.html('')
    this.arduinos = {}
    this.stop()
    let aid = 1
    let id = 0
    for (let row = 0; row < 6; row++) {
      const rowEl = jQuery('<div/>', { class: 'stepperrow' })
      this.areaEl.append(rowEl)
      for (let col = 0; col < 12; col++) {
        const fourblockEl = jQuery('<div/>', { class: 'fourblock' })
        fourblockEl.append(jQuery('<div/>', { class: 'centerblack' }))
        for (let i = 0; i < 4; i++) {
          const circleEl = jQuery('<div/>', { class: 'circle', id: 's' + (id++), 'data-unit': aid })
          circleEl.append(jQuery('<div/>', { class: 'square' }))
          fourblockEl.append(circleEl)
        }
        rowEl.append(fourblockEl)

        const arduino = new Arduino(aid)
        arduino.setRoot(oproot)
        this.arduinos[aid] = arduino
        aid++
      }
    }
  }

  reset (oproot) {
    for (let aid = 1; aid < 73; aid++) {
      const arduino = new Arduino(aid)
      arduino.setRoot(oproot)
      this.arduinos[aid] = arduino
    }
    for (let i = 0; i < 288; i++) {
      jQuery('#s' + i).css('transform', '')
    }
  }

  stop () {
    if (this.runTimeout !== null) {
      clearTimeout(this.runTimeout)
      this.runTimeout = null
    }
  }

  run () {
    this.stop()
    this._run()
  }

  _run () {
    if (!this.arduinos) {
      console.error('Do not run, because no arduinos exist')
      return
    }
    const speedup = jQuery('#speedupSimulation').is(':checked')
    const speedupAmount = parseInt(jQuery('#speedupAmountSimulation').val())
    const speed = 20
    for (let a = 1; a < 73; a++) {
      this.arduinos[a].loop(speedup ? speedupAmount * speed : speed)
    }
    const parent = this
    this.runTimeout = setTimeout(function () { parent._run() }, speed)
  }
}
