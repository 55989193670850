import Tabulator from 'tabulator-tables'
import 'tabulator-tables/dist/css/bootstrap/tabulator_bootstrap4.min.css'
import jQuery from 'jquery'
import { showError } from '../error'
import { HbsComponent } from '../web/hbscomponents'
import { showModal, hideModal } from '../web/modal'
import stepper288api from '../stepper288'
import moment from 'moment'
window.moment = moment

const formComponent = new HbsComponent('steplang/form.hbs', null)

function getNumber (value) {
  const num = parseInt(value, 10)
  return isNaN(num) ? 0 : num
}

export class StepLangOverview {
  constructor (tableId) {
    this.tableId = tableId
    this.api = stepper288api()
    window.api = this.api
    console.log(this.api)
  }

  load () {
    const overview = this
    this.loadStepLangs(
      function (stepLangs) {
        overview.loadStepLangTable(stepLangs, function () { overview._registerStepLangTableButtons(stepLangs) })
      })
    jQuery('#create_stepLang_button').click(function () {
      overview.renderStepLangForm({
        enable: true,
        x1: 0,
        x2: 0,
        x3: 0,
        x1_max: 0,
        x2_max: 0,
        x3_max: 0,
        minDuration: 300,
        maxDuration: 600,
        priority: 5,
        code: 'if (V_CUSTOM1 == 0) {\n' +
          '    maxSpeed(1000);\n' +
          '    V_CUSTOM1 = 1;\n' +
          '    V_CUSTOM18 = (C_STEPPER_ROW / 2) - (C_STEPPER_ROW % 2); // ROW per 4group (0-5)\n' +
          '    V_CUSTOM19 = (C_STEPPER_COL / 2) - (C_STEPPER_COL % 2); // COL per 4group (0-11)\n' +
          '} else if (V_CUSTOM1 == 1) {\n' +
          '}\n'
      })
    })
  }

  renderStepLangForm (data) {
    const overview = this
    formComponent.getHtml(function (content) {
      const el = jQuery('#steplang-form-container')
      el.html(content)
      if (data.code) {
        window.editor.getSession().setValue(data.code)
      }
      overview.buildCreateStepLangForm(el)
    }, data)
  }

  loadStepLangs (callback) {
    console.log('loading stepLangs')
    this.api.steplangListAll(function (error, data, response) {
      if (error) {
        showError('Error when loading all instances', error, response)
      } else {
        console.log('Loading stepLangs called successfully.')
        callback(data)
      }
    })
  }

  loadStepLangTable (stepLangs, callback) {
    buildStepLangTable(this.tableId, stepLangs, callback)
  }

  reloadTable () {
    console.log('API called successfully.')
    const overview = this
    overview.loadStepLangs(function (stepLangs) {
      overview.loadStepLangTable(stepLangs, function () { overview._registerStepLangTableButtons(stepLangs) })
    })
  }

  buildCreateStepLangForm (el) {
    console.log('build create stepLang form')
    const overview = this
    const formEl = el.find('form')
    formEl.on('submit', function (e) { // use on if jQuery 1.7+
      e.preventDefault() // prevent form from submitting
      const data = formEl.serializeArray()
      const req = {}
      for (const field of data) {
        req[field.name] = field.value
      }
      req.enable = !!req.enable
      req.x1 = getNumber(req.x1)
      req.x2 = getNumber(req.x2)
      req.x3 = getNumber(req.x3)
      req.x1_max = getNumber(req.x1_max)
      req.x2_max = getNumber(req.x2_max)
      req.x3_max = getNumber(req.x3_max)
      req.priority = getNumber(req.priority)
      req.minDuration = getNumber(req.minDuration)
      req.maxDuration = getNumber(req.maxDuration)
      req.code = window.editor.getSession().getValue()
      console.log(req)
      if (req.id) {
        req.id = parseInt(req.id)
        overview.api.steplangUpdate(req.id, req, function (error, data, response) {
          if (error) {
            showError('Error when updating a stepLang', error, response)
          } else {
            console.log('API called successfully.')
            overview.renderStepLangForm(data)
            overview.reloadTable()
          }
        })
      } else {
        delete req.id
        overview.api.steplangCreate(req, function (error, data, response) {
          if (error) {
            showError('Error when creating a stepLang', error, response)
          } else {
            console.log('API called successfully.')
            overview.renderStepLangForm(data)
            overview.reloadTable()
          }
        })
      }
    })
  }

  _registerStepLangTableButtons (stepLangs) {
    const overview = this
    jQuery(this.tableId).find('.steplang-btn').click(function (ev) {
      ev.preventDefault()
      const el = jQuery(ev.target)
      const id = el.data('steplang-id')
      const action = el.data('steplang-action')
      const value = el.data('steplang-value')
      let stepLang = null
      for (const ser of stepLangs) {
        if (ser.id === id) {
          stepLang = ser
          break
        }
      }
      if (stepLang == null) {
        console.error('Could not find an stepLang for this button')
        return
      }
      console.log('button->', action, stepLang, value)
      if (action === 'update') {
        overview.renderStepLangForm(stepLang)
      } else if (action === 'delete') {
        showModal('Really delete?', '<button id="steplang-really-delete">Delete</button>', null, function () {
          console.log('delete rendered')
          jQuery('#steplang-really-delete').click(function (ev) {
            console.log('delete clicked')
            ev.preventDefault()
            overview.api.steplangDelete(id, function (error, data, response) {
              if (error) {
                showError('Error when creating a stepLang', error, response)
              } else {
                console.log('API called successfully.')
                overview.reloadTable()
                hideModal()
              }
            })
          })
        })
      }
    })
  }
}

function buildStepLangTable (htmlId, data, callback) {
  const tab = new Tabulator(htmlId, {
    data: data, // load row data from array
    layout: 'fitColumns', // fit columns to width of table
    responsiveLayout: 'hide', // hide columns that dont fit on the table
    tooltips: true, // show tool tips on cells
    addRowPos: 'top', // when adding a new row, add it to the top of the table
    history: true, // allow undo and redo actions on the table
    pagination: 'local', // paginate the data
    paginationSize: 50, // allow 7 rows per page of data
    movableColumns: true, // allow column order to be changed
    resizableRows: true, // allow row order to be changed
    initialSort: [ // set the initial sort order of the data
      { column: 'name', dir: 'asc' }
    ],
    columns: [ // define the table columns
      { title: 'Interact', field: 'id', formatter: interactionFormatter },
      { title: 'Priority', field: 'priority' },
      { title: 'Title', field: 'title' },
      { title: 'Description', field: 'description' },
      //{ title: 'Created', field: 'created', formatter: 'datetimediff', formatterParams: { humanize: true, suffix: true } },
      //{ title: 'Updated', field: 'updated', formatter: 'datetimediff', formatterParams: { humanize: true, suffix: true } },
      { title: 'Code', field: 'code', formatter: codeFormatter }
    ]
  })
  callback()
  return tab
}

function codeFormatter (cell, formatterParams, onRendered) {
  return cell.getValue()
}

function interactionFormatter (cell, formatterParams, onRendered) {
  // cell - the cell component
  // formatterParams - parameters set for the column
  // onRendered - function to call when the formatter has been rendered

  return '<button class="steplang-btn btn btn-primary" type="button" data-steplang-id="' + cell.getValue() + '" data-steplang-action="update">update</button>' +
    '<button class="steplang-btn btn btn-primary" type="button" data-steplang-id="' + cell.getValue() + '" data-steplang-action="delete">delete</button>'
  // '<button class="steplang-btn btn btn-primary" type="button" data-steplang-id="'+cell.getValue()+'" data-steplang-action="duration" data-steplang-value="-60">-1h</button>'+
  // '<button class="steplang-btn btn btn-danger" type="button" data-steplang-id="'+cell.getValue()+'" data-steplang-action="stop">stop</button>';
}
