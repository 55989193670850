import antlr4 from 'antlr4'
import {
  SteplangGrammarLexer,
  SteplangGrammarParser
} from '../grammar/SteplangGrammar.g4'
import StepToCppVisitor from './transform-visitor'
import { ExpressionType, OperationType } from './enum'
import {
  OpRoot,
  OpIfElse
} from './model'
import jQuery from 'jquery'
const download = require('downloadjs')

export default function transform (name, input) {
  const expOp = transformExpOp(input)
  if (expOp == null) {
    return null
  }
  const expressions = expOp[0]
  const operations = expOp[1]
  const rootOperation = expOp[2]

  const cppResult = printCpp(expressions, operations)
  const serializedResult = printSerialized(name, input, expressions, operations)
  jQuery('#downloadSer').off('click').click(function (ev) {
    ev.preventDefault()
    download(serializedResult.code, serializedResult.filename, 'text/plain')
  })
  window.cppeditor.setValue(input.split('\n').map(x => '// ' + x).join('\n') + '\n' + cppResult + '\n' + serializedResult.code)
  return rootOperation
}

export function transformExpOp (input) {
  const stream = new antlr4.InputStream(input)
  const lexer = new SteplangGrammarLexer(stream)
  const tokens = new antlr4.CommonTokenStream(lexer)
  const parser = new SteplangGrammarParser(tokens)
  const tree = parser.root()
  if (tree == null) {
    window.cppeditor.setValue('Error when parsing the input')
    console.error('Error when parsing', input)
    return null
  }
  let rootOperation = null
  let expressions = []
  try {
    const visitor = new StepToCppVisitor()
    tree.accept(visitor)
    rootOperation = visitor.opStack[0]
    expressions = visitor.expressions
  } catch (error) {
    window.cppeditor.setValue('Error when transforming the input: ' + error + '\non:\n' + error.stack)
    console.error('Error when transforming', error, input)
    return null
  }
  const operations = linearizeOperations([rootOperation])
  fillInOperationIndex(expressions, operations)
  return [expressions, operations, rootOperation]
}

function fillInOperationIndex (expressions, operations) {
  for (let i = 0; i < expressions.length; i++) {
    const expr = expressions[i]
    if (expr.m_type === ExpressionType.I_FUNCTION) {
      operations.push(expr.m_left)
      expressions[i].m_leftidx = operations.length - 1
    }
  }
}

function linearizeOperations (operations) {
  let idx = 0
  do {
    const op = operations[idx]
    if (op instanceof OpIfElse || op instanceof OpRoot) {
      const subOps = op.m_subOperations
      op.m_subIdx = operations.length
      op.m_subLength = subOps.length
      operations.push(...subOps)
      if (op instanceof OpIfElse) {
        const elseOps = op.m_elseOperations
        if (elseOps != null) {
          op.m_elseIdx = operations.length
          op.m_elseLength = elseOps.length
          operations.push(...elseOps)
        }
      }
    }
    idx++
  } while (idx < operations.length)
  return operations
}

class SerializedOperation {
  constructor () {
    this.type = null
    this.op_length = null // length of sub-operations
    this.sub_op = null // start index of sub-operations
    this.exp = null // idx of an expression
  }

  print (i) {
    let ret = 'serOperations[' + i + '].type = ' + OperationType[this.type] + ';'
    if (this.op_length !== null) {
      ret += 'serOperations[' + i + '].op_length = ' + this.op_length + ';'
    }
    if (this.sub_op !== null) {
      ret += 'serOperations[' + i + '].sub_op = ' + this.sub_op + ';'
    }
    if (this.exp !== null) {
      ret += 'serOperations[' + i + '].exp = ' + this.exp + ';'
    }
    return ret + '\n'
  }

  toArray () {
    let tmp = 0
    const opLength = this.op_length ? this.op_length : 0
    tmp |= (this.type & 0xFF) << 8
    tmp |= opLength & 0xFF
    const ret = []
    ret.push(tmp)
    if (this.sub_op) {
      if (typeof this.sub_op === 'string') {
        ret.push(ExpressionType[this.sub_op])
      } else {
        ret.push(this.sub_op)
      }
    } else {
      ret.push(0)
    }
    ret.push(this.exp ? this.exp : 0)
    return ret
  }
}

class SerializedExpression {
  constructor () {
    this.type = null

    this.left = null
    this.right = null

    this.value = null
  }

  print (i) {
    let ret = 'serExpressions[' + i + '].type = ' + ExpressionType[this.type] + ';'
    if (this.left !== null) {
      ret += 'serExpressions[' + i + '].left = ' + this.left + ';'
    }
    if (this.right !== null) {
      ret += 'serExpressions[' + i + '].right = ' + this.right + ';'
    }
    if (this.value !== null) {
      if (this.value === 'X1') {
        ret += 'serExpressions[' + i + '].value = m_x1;'
      } else if (this.value === 'X2') {
        ret += 'serExpressions[' + i + '].value = m_x2;'
      } else if (this.value === 'X3') {
        ret += 'serExpressions[' + i + '].value = m_x3;'
      } else {
        ret += 'serExpressions[' + i + '].value = ' + this.value + ';'
      }
    }
    return ret + '\n'
  }

  toArray () {
    const ret = []
    ret.push(this.type)
    if (this.value != null) {
      // value is 32bit and needs to be split into two 16bit
      // >>> - is logical right shift and will also shift the signed bit
      ret.push(this.value & 0xFFFF)
      ret.push(this.value >>> 16)
    } else {
      const left = this.left ? this.left : 0
      const right = this.right ? this.right : 0
      ret.push(left)
      ret.push(right)
    }
    return ret
  }
};

/**
 * this function prints first cpp and then the serialized variant
 */
function printCpp (expressions, operations) {
  let result = ''
  result = ''
  result += 'allocOperations(' + operations.length + ');\n'
  result += 'allocExpressions(' + expressions.length + ');\n'
  for (let i = 0; i < expressions.length; i++) {
    const e = expressions[i]
    result += 'allExpressions[' + i + '] = ' + e.printCpp(expressions) + ';\n'
  }
  for (let i = 0; i < operations.length; i++) {
    const o = operations[i]
    result += 'allOperations[' + i + '] = ' + o.printCpp(expressions) + ';\n'
  }
  return result
}

function countNewlyAddedUntil (operations, until) {
  let newlyAdded = 0
  for (let j = 0; j < until; j++) {
    const op = operations[j]
    // finds all the previous ifs which had an else
    if (op instanceof OpIfElse && op.m_elseOperations != null) {
      newlyAdded++
    }
  }
  return newlyAdded
}

export function transformSerialized (expressions, operations) {
  const allSerExpressions = []
  for (let i = 0; i < expressions.length; i++) {
    const e = expressions[i]
    const serExp = new SerializedExpression()
    serExp.type = e.m_type
    if (e.m_value !== null && e.m_value !== undefined) {
      serExp.value = e.m_value
    } else if (e.m_left !== null && e.m_left !== undefined) {
      serExp.left = e.m_leftidx
      if (e.m_right !== null && e.m_right !== undefined) {
        serExp.right = e.m_rightidx
      }
    }
    allSerExpressions.push(serExp)
  }
  // transforming ifelse is a bit more complex
  const operationsWithSplitIfElse = []
  for (let i = 0; i < operations.length; i++) {
    const op = operations[i]
    operationsWithSplitIfElse.push(op)
    if (op instanceof OpIfElse && op.m_elseOperations != null) {
      // count how many OpIfElse with an else-branch before this one and updates the subIdx
      op.m_subIdx += countNewlyAddedUntil(operations, op.m_subIdx)
      const newOp = new OpIfElse(null, op.m_elseOperations)
      newOp.m_subIdx = op.m_elseIdx
      // count how many OpIfElse with an else-branch before this one and updates the subIdx
      newOp.m_subIdx += countNewlyAddedUntil(operations, newOp.m_subIdx)
      newOp.m_subLength = op.m_elseLength
      newOp.m_type = OperationType.OT_ELSE
      operationsWithSplitIfElse.push(newOp)
    } else {
      op.m_subIdx += countNewlyAddedUntil(operations, op.m_subIdx)
    }
  }

  // now the length needs to be updated too
  operationsWithSplitIfElse[0].updateLengthWhenChildIsOpElse(operationsWithSplitIfElse)

  console.log(allSerExpressions)
  for (let i = 0; i < allSerExpressions.length; i++) {
    const exp = allSerExpressions[i]
    if (exp.type === ExpressionType.I_FUNCTION) {
      console.log(exp)
      allSerExpressions[i].left += countNewlyAddedUntil(operations, exp.left)
    }
  }
  // when serializing if/else is handled special (this is ugly, but actually saves a lot of bytes so is worth it)
  // .. (actually its not worth it because it cost quite a lot time.. but now I'm inside "sunken cost fallacy")
  const allSerOperations = []
  for (let i = 0; i < operationsWithSplitIfElse.length; i++) {
    const o = operationsWithSplitIfElse[i]
    const serop = new SerializedOperation()
    serop.type = o.m_type
    if (o.m_condition !== null && o.m_condition !== undefined) {
      serop.exp = o.m_conditionIdx
    }
    // ugly: OpAssign encode it in sub_op
    if (o.m_variable !== null && o.m_variable !== undefined) {
      console.log(o.m_variable)
      serop.sub_op = ExpressionType[o.m_variable]
      serop.exp = o.m_valueIdx
    // loop/ifelse
    } else if (o.m_subIdx !== null && o.m_subIdx !== undefined && !isNaN(o.m_subIdx)) {
      serop.sub_op = o.m_subIdx
      serop.op_length = o.m_subLength
    // 2parameter functions: ugly encoded also in sub_op
    } else if (o.m_value !== null && o.m_value !== undefined && o.m_value2 !== null && o.m_value2 !== undefined) {
      serop.exp = o.m_valueIdx
      serop.sub_op = o.m_value2Idx
    // all other
    } else if (o.m_value !== null && o.m_value !== undefined) {
      serop.exp = o.m_valueIdx
    }
    allSerOperations.push(serop)
  }
  return [allSerExpressions, allSerOperations]
}

function printSerialized (name, input, expressions, operations) {
  let result = ''
  const serialized = transformSerialized(expressions, operations)
  const allSerExpressions = serialized[0]
  const allSerOperations = serialized[1]
  if (!name) {
    name = ''
  }

  const filename = name.toLowerCase().replace(/[^a-z0-9_]/g, '_')

  let progName = filename.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
      .replace('-', '')
      .replace('_', '')
  )
  progName = progName.charAt(0).toUpperCase() + progName.slice(1)

  result += '#define DEBUG\n'
  result += '#ifndef prog_' + filename + '_h\n'
  result += '#define prog_' + filename + '_h\n'
  result += '#include "../Debug/Debug.h"\n'
  result += '#include "prog.h"\n'
  result += 'class ' + progName + 'Definition: public ProgramDefinition {\n'
  result += '  public:\n'

  result += '    const char* getName() override {\n'
  result += '        return "' + name + '";\n'
  result += '    }\n\n'

  result += '    const uint8_t getPriority() override {\n'
  result += '        return ' + jQuery('#priority').val() + ';\n'
  result += '    }\n\n'

  result += '    const uint16_t getDuration() override {\n'
  result += '        return random(' + jQuery('#prog_min_duration').val() + ', ' + jQuery('#prog_max_duration').val() + ');\n'
  result += '    }\n\n'

  result += '    void setX1X2X3(uint32_t x1, uint32_t x2, uint32_t x3) override {\n'
  const x1Max = jQuery('#prog_x1_max').val()
  const x2Max = jQuery('#prog_x2_max').val()
  const x3Max = jQuery('#prog_x3_max').val()
  if (x1Max === '0') {
    result += '        m_x1 = 0;\n'
  } else {
    result += '        m_x1 = x1 % ' + x1Max + ';\n'
  }
  if (x2Max === '0') {
    result += '        m_x2 = 0;\n'
  } else {
    result += '        m_x2 = x2 % ' + x2Max + ';\n'
  }
  if (x3Max === '0') {
    result += '        m_x3 = 0;\n'
  } else {
    result += '        m_x3 = x3 % ' + x3Max + ';\n'
  }
  result += '    }\n\n'

  result += '    void instantiate() override {\n'
  result += '      PRINTLN("' + name + '")\n'
  result += '      free();\n'
  result += '      free();\n'
  result += '      m_operationSize = ' + allSerOperations.length + ';\n'
  result += '      m_expressionSize = ' + allSerExpressions.length + ';\n'
  result += '      m_operations = new SerializedOperation[m_operationSize];\n'
  result += '      SerializedOperation* serOperations = m_operations;\n'
  result += '      m_expressions = new SerializedExpression[m_expressionSize];\n'
  result += '      SerializedExpression* serExpressions = m_expressions;\n'
  result += '\n'
  result += input.split('\n').map(x => '      // ' + x).join('\n')
  result += '\n'
  for (let i = 0; i < allSerExpressions.length; i++) {
    result += '      ' + allSerExpressions[i].print(i)
  }
  for (let i = 0; i < allSerOperations.length; i++) {
    result += '      ' + allSerOperations[i].print(i)
  }
  result += '    }\n'
  result += '};\n'
  const enumName = 'P_' + filename.toUpperCase()
  result += '// add to Jukebox.h\n'
  result += '// ' + enumName + ',\n'
  result += '// add to Jukebox.cpp\n'
  result += '// #include "../prog/' + filename + '.h"\n'
  result += '// m_allPrograms[' + enumName + '] = new ' + progName + 'Definition();\n'
  result += '#endif'
  return { filename: filename + '.h', code: result }
}

window.transform = transform
