import { HbsComponent } from '../web/hbscomponents'
import { Register, WriteRegisterPrefix, RunningStates, ExpressionType } from '../steplang/enum'
import jQuery from 'jquery'
import stepper288api from '../stepper288'
import { showError } from '../error'
import { transformExpOp, transformSerialized } from '../steplang/transform'

const communicationComponent = new HbsComponent('communication/overview.hbs', '#communication-block')
const registerComponent = new HbsComponent('communication/register.hbs', null)
const api = stepper288api()

export function initCommunication () {
  communicationComponent.render(function (el) {
    handleGlobalCommunicationButtons(el)

    jQuery('.fourblock .circle').click(function (ev) {
      const el = jQuery(this)
      const unit = parseInt(el.data('unit'))
      updateRegisterView(unit)
    })
  })
}

function isModifiable (registerId) {
  const name = Register[registerId]
  if (name.includes('_V_') || name.includes('_D_')) {
    return true
  }
  if (registerId < Register.R_VARS) {
    if (name.includes('VERSION') || name.includes('NUMBER')) {
      return false
    }
    return true
  }
  return false
}

function enhanceReceivedModbusData (data) {
  return data.map(function (register) {
    register.name = Register[register.id]
    register.modifiable = isModifiable(register.id)

    register.isStepper = false
    register.isStepper1 = false
    register.isStepper2 = false
    register.isStepper3 = false
    register.isStepper4 = false
    for (let i = 1; i < 5; i++) {
      if (register.name.includes('_S' + i + '_') || register.name.includes('_' + i + '_')) {
        register.isStepper = true
        register['isStepper' + i] = true
      }
    }
    register.name = register.name.replace(/^R_/, '').replace(/^VARS_[0-9]_/, '').replace(/^S[0-9]_/, '')
    return register
  }).filter(function (register) {
    if (register.name.startsWith('I_')) {
      return false
    }
    return true
  })
}

function updateRegisterView (unit, stepper) {
  window.Register = Register
  let minReg = 0
  let regLength = Register.R_VARS
  if (stepper > 0) {
    minReg = Register.R_VARS + 1 + (stepper - 1) * (ExpressionType.I_CUSTOM_LAST + 1)
    regLength = ExpressionType.I_CUSTOM_LAST
    console.log(minReg, regLength)
  }
  api.modbusReadMultipleRegister(unit, minReg, regLength, function (error, data, response) {
    if (error) {
      showError('Error when reading all register', error, response)
    } else {
      const enhancedData = enhanceReceivedModbusData(data)
      registerComponent.getHtml(function (html) {
        const el = jQuery('#registerArea')
        el.html(html)
        handleRegisterFields(el, unit)
      }, { unit: unit, registers: enhancedData })
    }
  })
}

function syncRegister (regInput, register, unit) {
  api.modbusReadRegister(unit, register, function (error, data, response) {
    if (error) {
      showError('Error when syncing a register', error, response)
    } else {
      regInput.val(data)
      regInput.data('reg-val', data)
      regInput.trigger('keyup') // trigger this, so the right css-class is loaded
    }
  })
}

function handleRegisterFields (el, unit) {
  handleGlobalCommunicationButtons(el)
  el.find('.reg-sync-many').click(function () { updateRegisterView(unit, jQuery(this).data('for-stepper')) })
  el.find('.reg-input').keyup(function () {
    const el = jQuery(this)
    /* eslint-disable eqeqeq */
    if (el.data('reg-val') != el.val()) {
      jQuery(this).addClass('text-decoration-underline')
    } else {
      jQuery(this).removeClass('text-decoration-underline')
    }
  })
  el.find('.reg-sync').click(function () {
    const regInputId = jQuery(this).attr('for')
    const regInput = jQuery('#' + regInputId)
    const register = regInput.data('reg-id')
    syncRegister(regInput, register, unit)
  })
  el.find('.reg-update').click(function () {
    const regInputId = jQuery(this).attr('for')
    const regInput = jQuery('#' + regInputId)
    const register = regInput.data('reg-id')
    writeRegister(unit, register, [parseInt(regInput.val())], function (error, data, response) {
      if (error) {
        showError('Error when updating a register', error, response)
      } else {
        syncRegister(regInput, register, unit)
      }
    })
  })
}

function sendProgram (unit, code, callback) {
  const expOp = transformExpOp(code)
  if (expOp == null) {
    showError('Could not send program', 'Program has an error', '')
    return
  }
  const expressions = expOp[0]
  const operations = expOp[1]
  const ser = transformSerialized(expressions, operations)
  const serExps = ser[0]
  const serOps = ser[1]

  const writeData = { header: [WriteRegisterPrefix.COM_WRITE_REGISTERS], chunks: [[serOps.length, serExps.length]] }
  const expData = { header: [WriteRegisterPrefix.COM_SEND_EXP], chunks: serExps.map(a => a.toArray()) }
  const opData = { header: [WriteRegisterPrefix.COM_SEND_OP], chunks: serOps.map(a => a.toArray()) }
  console.log(writeData, expData, opData)
  api.modbusUpdateRegister(unit, Register.R_OP_SIZE, writeData, function (error, data, response) {
    if (error) {
      showError('Error when writing registers for sendprogram', error, response)
    } else {
      stepper288api(40 * 1000).modbusUpdateRegister(unit, 0, expData, function (error, data, response) {
        if (error) {
          showError('Error when sending expressions', error, response)
        } else {
          stepper288api(40 * 1000).modbusUpdateRegister(unit, 0, opData, function (error, data, response) {
            if (error) {
              showError('Error when sending operations', error, response)
            } else {
              showError('Success', 'success', '')
              if (callback) {
                callback()
              }
            }
          })
        }
      })
    }
  })
}

function writeRegister (unit, register, values, callback) {
  api.modbusUpdateRegister(unit, register, { header: [WriteRegisterPrefix.COM_WRITE_REGISTERS], chunks: [values] }, callback)
}

function start (unit) {
  api.modbusUpdateRegister(unit, 0, { header: [], chunks: [[WriteRegisterPrefix.COM_START]] }, function (error, data, response) {
    if (error) {
      showError('Error when updating a register', error, response)
    } else {
      showError('Started', 'Started the program', '')
    }
  })
}

function handleGlobalCommunicationButtons (el) {
  el.find('.com-send-program').click(function () {
    sendProgram(jQuery(this).data('for-unit'), window.editor.getSession().getValue(), () => start(jQuery(this).data('for-unit')))
  })

  el.find('.com-start').click(function () { start(jQuery(this).data('for-unit')) })

  el.find('.com-pause').click(function () {
    writeRegister(jQuery(this).data('for-unit'), Register.R_RUNNING, [RunningStates.RUN_IDLE], function (error, data, response) {
      if (error) {
        showError('Error when updating a register', error, response)
      } else {
        showError('Paused', 'Paused the program', '')
      }
    })
  })

  el.find('.com-calibrate').click(function () {
    sendProgram(jQuery(this).data('for-unit'), 'if (V_CUSTOM1 == 0) {\n' +
      'V_CUSTOM1 = 1;\n' +
      'maxSpeed(3000);\n' +
      '} if (V_CUSTOM1 == 1 && calibrate(1) == 1) {\n' +
      'V_CUSTOM1 = 2;\n' +
      'moveAbs(0);\n' +
      '}', () => start(jQuery(this).data('for-unit'))
    )
  })

  el.find('.com-restart').click(function () {
    sendProgram(jQuery(this).data('for-unit'), 'restart();', () => start(jQuery(this).data('for-unit')))
  })
}
