// this module handles all the html components
// mostly its responsible just for the download - insert - delete cycle

import { HtmlComponent } from './htmlcomponents'
const Handlebars = require('handlebars')

export class HbsComponent extends HtmlComponent {
  constructor (path, insertEl) {
    super(path, insertEl)
    this.data = {}
  }

  render (callback = null, data = {}) {
    if (callback == null) {
      callback = function () {}
    }
    this.data = data
    const parent = this
    this.getHtml(function (content) {
      parent.addHtml(content, callback)
    }, data)
  }

  setContent (content) {
    this.content = Handlebars.compile(content)
  }

  getHtml (callback, data) {
    super.getHtml(function (content) {
      callback(content(data))
    })
  }
}
