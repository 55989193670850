// this module handles all the html components
// mostly its responsible just for the download - insert - delete cycle

import jQuery from 'jquery'

export class HtmlComponent {
  constructor (path, insertEl) {
    this.path = path
    this.id = this.makeId(path)
    this.rendered = false
    this.content = null
    this.insertEl = insertEl
  }

  makeId (str) {
    return str.replace(/[^a-zA-Z0-9]/g, '_')
  }

  getHtml (callback) {
    if (this.content) {
      callback(this.content)
    }
    const htmlComponentParent = this
    jQuery.get('/components/' + this.path, function (data) {
      htmlComponentParent.setContent(data)
      callback(htmlComponentParent.content)
    }).fail(function () {
      window.alert('could not load ' + htmlComponentParent.path)
    })
  }

  render (callback) {
    if (this.rendered) {
      callback(jQuery('#' + this.id))
      return
    }
    const htmlComponentParent = this
    this.getHtml(function (content) {
      htmlComponentParent.addHtml(content, callback)
    })
  }

  setContent (content) {
    this.content = content
  }

  /**
   * adds rendered html:
   * * if it was already added, it will just update it
   * * if it dows not exist, it will append it to insertEl
   */
  addHtml (content, callback) {
    this.rendered = true
    if (jQuery('#' + this.id).length) {
      console.log('update')
      jQuery('#' + this.id).html(content)
    } else {
      jQuery(this.insertEl).append('<div id="' + this.id + '">' + content + '</div>')
    }
    callback(jQuery('#' + this.id))
  }

  remove () {
    console.log('Removed', this.id)
    jQuery('#' + this.id).remove()
    this.rendered = false
  }
}
