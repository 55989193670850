import {
  editor,
  cppeditor
} from './editor/editor'
import { StepLangOverview } from './manage/manage'
import { Run } from './run/run'
import jQuery from 'jquery'
import transform from './steplang/transform'
import { initCommunication } from './communication/communication'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import 'bootstrap'
import { HbsComponent } from './web/hbscomponents'
window.$ = jQuery
window.jQuery = jQuery

window.cppeditor = cppeditor
window.editor = editor

// editor-code TODO move
function initEditor () {
  const run = new Run()
  run.init(null)

  jQuery('#runSimulation').click(function () {
    const rootOperation = transform(jQuery("#title").val(), editor.getSession().getValue())
    run.reset(rootOperation)
    run.run()
    window.run = run
  })
  jQuery('#stopSimulation').click(function () {
    run.stop()
  })
}

const helpComponent = new HbsComponent('help.hbs', jQuery('#help-block'))
const programsComponent = new HbsComponent('programs.hbs', jQuery('#programs-block'))
function initHtml () {
  helpComponent.render()
  programsComponent.render()
}

jQuery(function () {
  const stepLangOverview = new StepLangOverview('#stepLang-table')
  stepLangOverview.load()
})
initHtml()
initEditor()
initCommunication()
