import jQuery from 'jquery'
import { HbsComponent } from './hbscomponents'
import moment from 'moment'

const modalComponent = new HbsComponent('modal/modal.hbs', jQuery('#modalArea'))
// since we need it anyway
modalComponent.getHtml(function () {}, { body: '', title: '' })
let lastUpdated
let lastUpdatedIntervall

function _removeAllExisting () {
  const existingModals = jQuery('.modal')
  existingModals.removeClass('fade')
  jQuery('.modal-backdrop').removeClass('fade')
  existingModals.modal('dispose')
  existingModals.remove()
  jQuery('.modal-backdrop').remove()
  clearInterval(lastUpdatedIntervall)
}

export function showModal (title, body, hiddenCallback = null, loadedCallback = null) {
  if (!body) {
    body = 'Loading ...'
  }
  if (!hiddenCallback) {
    hiddenCallback = function () {}
  }
  if (!loadedCallback) {
    loadedCallback = function () {}
  }
  modalComponent.getHtml(function (content) {
    _removeAllExisting()
    lastUpdated = moment()
    lastUpdatedIntervall = setInterval(updateLastUpdated, 1000)
    jQuery('#modalArea').html(content)
    const modalEl = jQuery('#modalArea .modal')
    modalEl.modal('show')
    modalEl.on('hidden.bs.modal', function (event) {
      hiddenCallback()
      clearInterval(lastUpdatedIntervall)
    })
    loadedCallback(modalEl)
  }, { title: title, body: body })
}

export function updateModalBody (body) {
  jQuery('#genericModal .modal-body').html(body)
  jQuery('#modalArea .modal').modal('handleUpdate')
  lastUpdated = moment()
}

function updateLastUpdated () {
  if (lastUpdated) {
    const relTime = lastUpdated.fromNow()
    jQuery('#modalArea .modal-footer').html('<span class="text-muted" title="' + lastUpdated.format('hh:mm:ss') + '">Last Update: ' + relTime + '</span>')
  }
}

export function hideModal () {
  jQuery('#modalArea .modal').modal('hide')
}
