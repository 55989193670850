import { showModal } from './web/modal'

export function showError (title, error, response) {
  if (response && response.body.title) {
    if (response.body.detail) {
      showModal(title, '<strong>' + response.body.title + ':</strong><br/>' + response.body.detail)
    } else {
      showModal(title, response.body.title)
    }
  } else {
    showModal(title, error)
  }
}
