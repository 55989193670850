import transform from '../steplang/transform'
import jQuery from 'jquery'
const ace = require('ace-builds/src-noconflict/ace')
require('ace-builds/webpack-resolver')
require('ace-builds/src-noconflict/theme-idle_fingers')
export const editor = ace.edit()
const beautify = require('ace-builds/src-noconflict/ext-beautify')
require('ace-builds/src-noconflict/ext-settings_menu').init(editor)
require('ace-builds/src-noconflict/ext-language_tools')

require('./ace-steplang')

const buildDom = ace.require('ace/lib/dom').buildDom
editor.setOptions({
  theme: 'ace/theme/crimson_editor',
  mode: 'ace/mode/steplang',
  maxLines: 30,
  minLines: 30,
  autoScrollEditorIntoView: true,
  enableBasicAutocompletion: true,
  enableSnippets: true,
  enableLiveAutocompletion: true
})

// toolbar
const refs = {}
function updateToolbar () {
  refs.undoButton.disabled = !editor.session.getUndoManager().hasUndo()
  refs.redoButton.disabled = !editor.session.getUndoManager().hasRedo()
}
editor.on('input', updateToolbar)

export function attachEditor (elId) {
  buildDom(['div', { class: 'toolbar' },
    ['button', {
      ref: 'undoButton',
      onclick: function () {
        editor.undo()
      }
    }, 'undo'],
    ['button', {
      ref: 'redoButton',
      onclick: function () {
        editor.redo()
      }
    }, 'redo'],
    ['button', {
      // style: "font-weight: bold",
      onclick: function () {
        /* eslint-disable no-template-curly-in-string */
        editor.insertSnippet('if () {\n${1:$SELECTION}\n}')
        editor.renderer.scrollCursorIntoView()
      }
    }, 'if () { ... }'],
    ['button', {
      // style: "font-weight: bold",
      onclick: function () {
        /* eslint-disable no-template-curly-in-string */
        editor.insertSnippet('/*\n${1:$SELECTION}\n*/')
        editor.renderer.scrollCursorIntoView()
      }
    }, '/* ... */'],
    ['button', {
      onclick: function () {
        beautify.beautify(editor.session)
      }
    }, 'autoformat'],
    ['button', {
      onclick: function () {
        editor.showSettingsMenu()
      }
    }, 'settings']
  ], document.getElementById(elId), refs)
  document.getElementById(elId).appendChild(editor.container)
}
attachEditor('editor')

function updateResult () {
  const code = window.editor.getValue()
  transform(jQuery("#title").val(), code)
}
editor.getSession().on('change', function () {
  updateResult()
})

export const cppeditor = ace.edit('result')
cppeditor.setOptions({
  theme: 'ace/theme/crimson_editor',
  mode: 'ace/mode/c_cpp',
  maxLines: 30,
  minLines: 30,
  readOnly: true
})
