import {
  SteplangGrammarLexer,
  SteplangGrammarVisitor
} from '../grammar/SteplangGrammar.g4'
import {
  Expression,
  OpRoot,
  OpIfElse,
  OpAssign,
  OpSpeed,
  OpMaxSpeed,
  OpAcceleration,
  OpMoveRel,
  OpMoveAbs,
  OpCalibrate,
  OpEnable,
  OpDisable,
  OpStop,
  OpRestart,
  OpError,
  OpDigitalHigh,
  OpDigitalLow,
  OpRandom,
  OpAbs,
  OpMax,
  OpMin
} from './model'
import { ExpressionType } from '../steplang/enum'
import {
  error
} from './shared'

// This class defines a complete listener for a parse tree produced by ChatParser.
export default class StepToCppVisitor extends SteplangGrammarVisitor {
  constructor () {
    super()
    this.expressions = []
    this.opStack = [new OpRoot([])]
    this.lastOp = null
    this.lastExpr = null
    this.funcExpr = false
  }

  /**
   * adds a new expression to the list + stack and makes sure that they are deduplicated
   */
  addExpression (newExpr) {
    for (let i = 0; i < this.expressions.length; i++) {
      if (this.expressions[i].equals(newExpr)) {
        return
      }
    }
    this.expressions.push(newExpr)
  }

  getExpression (_) {
    return this.lastExpr
  }

  getExpressionIndex (expr) {
    for (let i = 0; i < this.expressions.length; i++) {
      if (this.expressions[i].equals(expr)) {
        return i
      }
    }
  }

  visitIdAtom (ctx) {
    const id = ctx.children[0].getText()
    let e
    if (id === 'X1' || id === 'X2') {
      e = new Expression(ExpressionType.I_ATOMAR, null, null, id)
    } else if (!(id in ExpressionType)) {
      error('Could not find ' + id + ' in ' + ExpressionType)
      e = new Expression(ExpressionType.I_ATOMAR, null, null, 1234567)
    } else {
      e = new Expression(ExpressionType[id], null, null, null)
    }
    this.addExpression(e)
    this.lastExpr = e
  }

  visitNumberAtom (ctx) {
    const e = new Expression(ExpressionType.I_ATOMAR, null, null, parseInt(ctx.getText()))
    this.addExpression(e)
    this.lastExpr = e
  }

  visitMultiplicationExpr (ctx) {
    const left = this.getExpression(this.visit(ctx.expr(0)))
    const right = this.getExpression(this.visit(ctx.expr(1)))
    let type = 1
    switch (ctx.op.type) {
      case SteplangGrammarLexer.MULT: type = ExpressionType.OP_MUL; break
      case SteplangGrammarLexer.DIV: type = ExpressionType.OP_DIV; break
      case SteplangGrammarLexer.MOD: type = ExpressionType.OP_MOD; break
      default: error('wrong mul-type ' + ctx.getText()); break
    }
    let e
    if (Number.isInteger(left.m_value) && Number.isInteger(right.m_value)) {
      const val = new Expression(type, left, right, null).getValue({})
      e = new Expression(ExpressionType.I_ATOMAR, null, null, val)
    } else {
      e = new Expression(type, left, right, null)
      e.m_leftidx = this.getExpressionIndex(left)
      e.m_rightidx = this.getExpressionIndex(right)
    }
    this.addExpression(e)
    this.lastExpr = e
  }

  visitAdditiveExpr (ctx) {
    const left = this.getExpression(this.visit(ctx.expr(0)))
    const right = this.getExpression(this.visit(ctx.expr(1)))
    let type = 1
    switch (ctx.op.type) {
      case SteplangGrammarLexer.PLUS: type = ExpressionType.OP_PLUS; break
      case SteplangGrammarLexer.MINUS: type = ExpressionType.OP_MINUS; break
      default: error('wrong add-type ' + ctx.getText()); break
    }
    let e
    if (Number.isInteger(left.m_value) && Number.isInteger(right.m_value)) {
      const val = new Expression(type, left, right, null).getValue({})
      e = new Expression(ExpressionType.I_ATOMAR, null, null, val)
    } else {
      e = new Expression(type, left, right, null)
      e.m_leftidx = this.getExpressionIndex(left)
      e.m_rightidx = this.getExpressionIndex(right)
    }
    this.addExpression(e)
    this.lastExpr = e
  }

  visitRelationalExpr (ctx) {
    const left = this.getExpression(this.visit(ctx.expr(0)))
    const right = this.getExpression(this.visit(ctx.expr(1)))
    let type = 1
    switch (ctx.op.type) {
      case SteplangGrammarLexer.LT: type = ExpressionType.OP_SMALLER; break
      case SteplangGrammarLexer.GT: type = ExpressionType.OP_GREATER; break
      default: error('wrong rel-type ' + ctx.getText()); break
    }
    const e = new Expression(type, left, right, null)
    e.m_leftidx = this.getExpressionIndex(left)
    e.m_rightidx = this.getExpressionIndex(right)
    this.addExpression(e)
    this.lastExpr = e
  }

  visitEqualityExpr (ctx) {
    const left = this.getExpression(this.visit(ctx.expr(0)))
    const right = this.getExpression(this.visit(ctx.expr(1)))
    let type = 1
    switch (ctx.op.type) {
      case SteplangGrammarLexer.EQ: type = ExpressionType.OP_EQUAL; break
      case SteplangGrammarLexer.NEQ: type = ExpressionType.OP_NOT_EQUAL; break
      default: error('wrong eq-type ' + ctx.getText()); break
    }
    const e = new Expression(type, left, right, null)
    e.m_leftidx = this.getExpressionIndex(left)
    e.m_rightidx = this.getExpressionIndex(right)
    this.addExpression(e)
    this.lastExpr = e
  }

  visitFuncExpr (ctx) {
    this.funcExpr = true
    this.visit(ctx.func())
    this.funcExpr = false
    const e = new Expression(ExpressionType.I_FUNCTION, this.lastOp)
    this.addExpression(e)
    this.lastExpr = e
  }

  visitAndExpr (ctx) {
    const left = this.getExpression(this.visit(ctx.expr(0)))
    const right = this.getExpression(this.visit(ctx.expr(1)))
    const e = new Expression(ExpressionType.OP_AND, left, right, null)
    e.m_leftidx = this.getExpressionIndex(left)
    e.m_rightidx = this.getExpressionIndex(right)
    this.addExpression(e)
    this.lastExpr = e
  }

  visitOrExpr (ctx) {
    const left = this.getExpression(this.visit(ctx.expr(0)))
    const right = this.getExpression(this.visit(ctx.expr(1)))
    const e = new Expression(ExpressionType.OP_OR, left, right, null)
    e.m_leftidx = this.getExpressionIndex(left)
    e.m_rightidx = this.getExpressionIndex(right)
    this.addExpression(e)
    this.lastExpr = e
  }

  visitAssignment (ctx) {
    const id = ctx.ID().getText()
    const expr = this.getExpression(this.visit(ctx.expr()))
    let op
    if (!(id in ExpressionType)) {
      op = new OpError(id + ' does not exist')
    } else if (id.substring(0, 1) !== 'V' && id.substring(0, 1) !== 'D' && id.substring(0, 1) !== 'G') {
      op = new OpError(id + ' is not assignable')
    } else {
      op = new OpAssign(ExpressionType[id], expr)
    }
    op.m_valueIdx = this.getExpressionIndex(expr)
    this.opStack[this.opStack.length - 1].m_subOperations.push(op)
    this.lastExpr = op
  }

  visitF_2param (ctx) {
    const p1 = this.getExpression(this.visit(ctx.p1()))
    const p2 = this.getExpression(this.visit(ctx.p2()))
    const id = ctx.children[0].getText()
    let op
    switch (id) {
      case 'max': op = new OpMax(p1, p2); break
      case 'min': op = new OpMin(p1, p2); break
      default: op = new OpError('Unknown method ' + id); break
    }
    op.m_valueIdx = this.getExpressionIndex(p1)
    op.m_value2Idx = this.getExpressionIndex(p2)
    if (!this.funcExpr) {
      this.opStack[this.opStack.length - 1].m_subOperations.push(op)
    }
    this.lastOp = op
  }

  visitF_1param (ctx) {
    const p1 = this.getExpression(this.visit(ctx.expr()))
    const id = ctx.children[0].getText()
    let op
    switch (id) {
      case 'speed': op = new OpSpeed(p1); break
      case 'maxSpeed': op = new OpMaxSpeed(p1); break
      case 'acceleration': op = new OpAcceleration(p1); break
      case 'moveRel': op = new OpMoveRel(p1); break
      case 'moveAbs': op = new OpMoveAbs(p1); break
      case 'calibrate': op = new OpCalibrate(p1); break
      case 'digitalWriteLow': op = new OpDigitalLow(p1); break
      case 'digitalWriteHigh': op = new OpDigitalHigh(p1); break
      case 'random': op = new OpRandom(p1); break
      case 'abs': op = new OpAbs(p1); break
      default: op = new OpError('Unknown method ' + id); break
    }
    op.m_valueIdx = this.getExpressionIndex(p1)
    if (!this.funcExpr) {
      this.opStack[this.opStack.length - 1].m_subOperations.push(op)
    }
    this.lastOp = op
  }

  visitF_0param (ctx) {
    const id = ctx.children[0].getText()
    let op
    switch (id) {
      case 'enable': op = new OpEnable(); break
      case 'disable': op = new OpDisable(); break
      case 'stop': op = new OpStop(); break
      case 'restart': op = new OpRestart(); break
      default: op = new OpError('Unknown method ' + id); break
    }
    if (!this.funcExpr) {
      this.opStack[this.opStack.length - 1].m_subOperations.push(op)
    }
    this.lastOp = op
  }

  visitIf_stat (ctx) {
    // transforming them is a bit tricky, it is either:
    // * a simple if: just one condition
    // * a simple if/else: just one condition but two bodies
    // * a nested if/else construct: each elseif will create also a new object
    const conditions = ctx.condition_block()
    let firstOpif = null
    let lastOpif = null
    for (let i = 0; i < conditions.length; i++) {
      const opif = new OpIfElse(null, [])
      this.opStack.push(opif)
      const condition = this.getExpression(this.visit(conditions[i].expr())[0])
      opif.m_conditionIdx = this.getExpressionIndex(condition)
      this.visit(conditions[i].stat_block())
      const lastOnStack = this.opStack.pop()
      lastOnStack.m_condition = condition
      lastOnStack.m_conditionIdx = this.getExpressionIndex(condition)
      if (firstOpif == null) {
        firstOpif = opif
        this.opStack[this.opStack.length - 1].m_subOperations.push(opif)
      } else {
        lastOpif.m_elseOperations = [opif]
      }
      lastOpif = opif
    }
    // this means there is an else-block
    if (ctx.stat_block() != null) {
      // they will attach themself to the dummy
      const dummyOpIf = new OpIfElse(null, [])
      this.opStack.push(dummyOpIf)
      this.visit(ctx.stat_block())
      this.opStack.pop()
      if (dummyOpIf.m_subOperations.length === 0) {
        lastOpif.m_elseOperations = null
      } else {
        lastOpif.m_elseOperations = dummyOpIf.m_subOperations
      }
    }
    return firstOpif
  }
}
