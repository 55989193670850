/* eslint-disable import/no-webpack-loader-syntax */
import Worker from 'worker-loader!./steplang-worker'
const ace = require('ace-builds/src-noconflict/ace')

ace.define('ace/mode/doc_comment_highlight_rules', ['require', 'exports', 'module', 'ace/lib/oop', 'ace/mode/text_highlight_rules'], function (require, exports, module) {
  const oop = require('ace/lib/oop')
  const TextHighlightRules = require('./text_highlight_rules').TextHighlightRules

  const DocCommentHighlightRules = function () {
    this.$rules = {
      start: [{
        token: 'comment.doc.tag',
        regex: '@[\\w\\d_]+' // TODO: fix email addresses
      },
      DocCommentHighlightRules.getTagRule(),
      {
        defaultToken: 'comment.doc',
        caseInsensitive: true
      }]
    }
  }

  oop.inherits(DocCommentHighlightRules, TextHighlightRules)

  DocCommentHighlightRules.getTagRule = function (start) {
    return {
      token: 'comment.doc.tag.storage.type',
      regex: '\\b(?:TODO|FIXME|XXX|HACK)\\b'
    }
  }

  DocCommentHighlightRules.getStartRule = function (start) {
    return {
      token: 'comment.doc', // doc comment
      regex: '\\/\\*(?=\\*)',
      next: start
    }
  }

  DocCommentHighlightRules.getEndRule = function (start) {
    return {
      token: 'comment.doc', // closing comment
      regex: '\\*\\/',
      next: start
    }
  }

  exports.DocCommentHighlightRules = DocCommentHighlightRules
})

ace.define('ace/mode/steplang_highlight_rules', ['require', 'exports', 'module', 'ace/lib/oop', 'ace/mode/text', 'ace/mode/golang_highlight_rules', 'ace/mode/matching_brace_outdent', 'ace/mode/behaviour/cstyle', 'ace/mode/folding/cstyle'], function (require, exports, module) {
  const oop = require('ace/lib/oop')
  const DocCommentHighlightRules = require('./doc_comment_highlight_rules').DocCommentHighlightRules
  const TextHighlightRules = require('./text_highlight_rules').TextHighlightRules

  const SteplangHighlightRules = function () {
    const keywordMapper = this.createKeywordMapper({
      keyword: 'if|else',
      'constant.language': 'C_STEPPER_GROUP|C_STEPPER_GROUP_NUM|C_STEPPER_ROW|C_STEPPER_COL|C_STEPPER_NUM|C_STEPS_TO_GO|C_CURRENT_STEP|C_IS_CALIBRATED|G_CUSTOM1|G_CUSTOM2|G_CUSTOM3|V_CUSTOM1|V_CUSTOM2|V_CUSTOM3|V_CUSTOM4|V_CUSTOM5|V_CUSTOM6|V_CUSTOM7|V_CUSTOM8|V_CUSTOM9|V_CUSTOM10|V_CUSTOM11|V_CUSTOM12|V_CUSTOM13|V_CUSTOM14|V_CUSTOM15|V_CUSTOM16|V_CUSTOM17|V_CUSTOM18|V_CUSTOM19|D_TIME_MS1|D_TIME_MS2|D_TIME_MS3|X1|X2',
      'support.function': 'random|max|min|abs|speed|maxSpeed|acceleration|moveRel|moveAbs|enable|disable|stop|calibrate|restart|digitalWriteHigh|digitalWriteLow'
    }, '')

    this.$rules = {
      start: [
        {
          token: 'comment',
          regex: '\\/\\/.*$'
        },
        DocCommentHighlightRules.getStartRule('doc-start'),
        {
          token: 'comment.start', // multi line comment
          regex: '\\/\\*',
          next: 'comment'
        }, {
          token: 'constant.numeric',
          regex: '[+-]?\\d+\\b'
        }, {
          token: function (val) {
            if (val[val.length - 1] === '(') {
              return [{
                type: keywordMapper(val.slice(0, -1)) || 'invalid',
                value: val.slice(0, -1)
              }, {
                type: 'paren.lparen',
                value: val.slice(-1)
              }]
            }

            return keywordMapper(val) || 'invalid'
          },
          regex: '[a-zA-Z_$][a-zA-Z0-9_$]*\\b\\(?'
        }, {
          token: 'paren.lparen',
          regex: '[[({]'
        }, {
          token: 'paren.rparen',
          regex: '[\\])}]'
        }
      ],
      comment: [
        {
          token: 'comment.end',
          regex: '\\*\\/',
          next: 'start'
        }, {
          defaultToken: 'comment'
        }
      ]
    }

    this.embedRules(DocCommentHighlightRules, 'doc-',
      [DocCommentHighlightRules.getEndRule('start')])
  }
  oop.inherits(SteplangHighlightRules, TextHighlightRules)

  exports.SteplangHighlightRules = SteplangHighlightRules
})

ace.define('ace/mode/folding/cstyle', ['require', 'exports', 'module', 'ace/lib/oop', 'ace/range', 'ace/mode/folding/fold_mode'], function (require, exports, module) {
  const oop = require('ace/lib/oop')
  const Range = require('ace/range').Range
  const BaseFoldMode = require('ace/mode/folding/fold_mode').FoldMode

  const FoldMode = exports.FoldMode = function (commentRegex) {
    if (commentRegex) {
      this.foldingStartMarker = new RegExp(
        this.foldingStartMarker.source.replace(/\|[^|]*?$/, '|' + commentRegex.start)
      )
      this.foldingStopMarker = new RegExp(
        this.foldingStopMarker.source.replace(/\|[^|]*?$/, '|' + commentRegex.end)
      )
    }
  }
  oop.inherits(FoldMode, BaseFoldMode);

  (function () {
    this.foldingStartMarker = /([{[(])[^}]\)]*$|^\s*(\/\*)/
    this.foldingStopMarker = /^[^[{(]*([}]\)])|^[\s*]*(\*\/)/
    this.singleLineBlockCommentRe = /^\s*(\/\*).*\*\/\s*$/
    this.tripleStarBlockCommentRe = /^\s*(\/\*\*\*).*\*\/\s*$/
    this.startRegionRe = /^\s*(\/\*|\/\/)#?region\b/
    this._getFoldWidgetBase = this.getFoldWidget
    this.getFoldWidget = function (session, foldStyle, row) {
      const line = session.getLine(row)

      if (this.singleLineBlockCommentRe.test(line)) {
        if (!this.startRegionRe.test(line) && !this.tripleStarBlockCommentRe.test(line)) { return '' }
      }

      const fw = this._getFoldWidgetBase(session, foldStyle, row)

      if (!fw && this.startRegionRe.test(line)) { return 'start' } // lineCommentRegionStart

      return fw
    }

    this.getFoldWidgetRange = function (session, foldStyle, row, forceMultiline) {
      const line = session.getLine(row)

      if (this.startRegionRe.test(line)) { return this.getCommentRegionBlock(session, line, row) }

      let match = line.match(this.foldingStartMarker)
      if (match) {
        const i = match.index

        if (match[1]) { return this.openingBracketBlock(session, match[1], row, i) }

        let range = session.getCommentFoldRange(row, i + match[0].length, 1)

        if (range && !range.isMultiLine()) {
          if (forceMultiline) {
            range = this.getSectionRange(session, row)
          } else if (foldStyle !== 'all') { range = null }
        }

        return range
      }

      if (foldStyle === 'markbegin') { return }

      match = line.match(this.foldingStopMarker)
      if (match) {
        const i = match.index + match[0].length

        if (match[1]) { return this.closingBracketBlock(session, match[1], row, i) }

        return session.getCommentFoldRange(row, i, -1)
      }
    }

    this.getSectionRange = function (session, row) {
      let line = session.getLine(row)
      const startIndent = line.search(/\S/)
      const startRow = row
      const startColumn = line.length
      row = row + 1
      let endRow = row
      const maxRow = session.getLength()
      while (++row < maxRow) {
        line = session.getLine(row)
        const indent = line.search(/\S/)
        if (indent === -1) { continue }
        if (startIndent > indent) { break }
        const subRange = this.getFoldWidgetRange(session, 'all', row)

        if (subRange) {
          if (subRange.start.row <= startRow) {
            break
          } else if (subRange.isMultiLine()) {
            row = subRange.end.row
          } else if (startIndent === indent) {
            break
          }
        }
        endRow = row
      }

      return new Range(startRow, startColumn, endRow, session.getLine(endRow).length)
    }
    this.getCommentRegionBlock = function (session, line, row) {
      const startColumn = line.search(/\s*$/)
      const maxRow = session.getLength()
      const startRow = row

      const re = /^\s*(?:\/\*|\/\/|--)#?(end)?region\b/
      let depth = 1
      while (++row < maxRow) {
        line = session.getLine(row)
        const m = re.exec(line)
        if (!m) continue
        if (m[1]) depth--
        else depth++

        if (!depth) break
      }

      const endRow = row
      if (endRow > startRow) {
        return new Range(startRow, startColumn, endRow, line.length)
      }
    }
  }).call(FoldMode.prototype)
})

ace.define('ace/mode/steplang', ['require', 'exports', 'module', 'ace/lib/oop', 'ace/mode/text', 'ace/mode/sh_highlight_rules', 'ace/range', 'ace/mode/folding/cstyle', 'ace/mode/behaviour/cstyle'], function (require, exports, module) {
  const oop = require('ace/lib/oop')
  const TextMode = require('ace/mode/text').Mode
  const SteplangHighlightRules = require('./steplang_highlight_rules').SteplangHighlightRules
  const Range = require('../range').Range
  const CStyleFoldMode = require('./folding/cstyle').FoldMode
  const CstyleBehaviour = require('./behaviour/cstyle').CstyleBehaviour

  const Mode = function () {
    this.HighlightRules = SteplangHighlightRules
    this.foldingRules = new CStyleFoldMode()
    this.$behaviour = new CstyleBehaviour()
  }
  oop.inherits(Mode, TextMode);

  (function () {
    this.lineCommentStart = '#'

    this.getNextLineIndent = function (state, line, tab) {
      let indent = this.$getIndent(line)

      const tokenizedLine = this.getTokenizer().getLineTokens(line, state)
      const tokens = tokenizedLine.tokens

      if (tokens.length && tokens[tokens.length - 1].type === 'comment') {
        return indent
      }

      if (state === 'start') {
        const match = line.match(/^.*[{([:]\s*$/)
        if (match) {
          indent += tab
        }
      }

      return indent
    }

    const outdents = {
      pass: 1,
      return: 1,
      raise: 1,
      break: 1,
      continue: 1
    }

    this.checkOutdent = function (state, line, input) {
      if (input !== '\r\n' && input !== '\r' && input !== '\n') { return false }

      const tokens = this.getTokenizer().getLineTokens(line.trim(), state).tokens

      if (!tokens) { return false }
      let last
      do {
        last = tokens.pop()
      } while (last && (last.type === 'comment' || (last.type === 'text' && last.value.match(/^\s+$/))))

      if (!last) { return false }

      return (last.type === 'keyword' && outdents[last.value])
    }

    this.autoOutdent = function (state, doc, row) {
      row += 1
      const indent = this.$getIndent(doc.getLine(row))
      const tab = doc.getTabString()
      if (indent.slice(-tab.length) === tab) { doc.remove(new Range(row, indent.length - tab.length, row, indent.length)) }
    }

    this.$id = 'ace/mode/steplang'
    this.snippetFileId = 'ace/snippets/steplang'

    const WorkerClient = require('ace/worker/worker_client').WorkerClient
    class WebpackWorkerClient extends WorkerClient {
      constructor (worker) {
        super(worker)
        this.$sendDeltaQueue = this.$sendDeltaQueue.bind(this)
        this.changeListener = this.changeListener.bind(this)
        this.onMessage = this.onMessage.bind(this)
        this.$worker = worker
        this.callbackId = 1
        this.callbacks = {}
        this.$worker.onmessage = this.onMessage
      }
    }

    this.createWorker = function (session) {
      this.$worker = new WebpackWorkerClient(new Worker())
      this.$worker.attachToDocument(session.getDocument())

      this.$worker.on('errors', function (e) {
        session.setAnnotations(e.data)
      })

      this.$worker.on('annotate', function (e) {
        session.setAnnotations(e.data)
      })

      this.$worker.on('terminate', function () {
        session.clearAnnotations()
      })

      return this.$worker
    }
  }).call(Mode.prototype)

  exports.Mode = Mode
})
